<script>
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep'
import Swal from 'sweetalert2';

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      valid: true,
      customers: [],
      roleItems: [
        { value: 'manager', text: 'Administrador' },
        { value: 'consultant', text: 'Consultor' },
        { value: 'customer', text: 'Cliente' },
      ],

      form: {},

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        customer: [(v) => !!v || 'Cliente é obrigatório'],
        email: [(v) => !v || /.+@.+/.test(v) || 'E-mail inválido'],
      },
    };
  },

  async created() {
    if (this.id !== 'new') {
      this.form = await this.fetch();
      if (this.form.customers) {
        this.form.customer_ids = this.form.customers.map((item) => item.customer.id);
      }
      delete this.form.customers;
      delete this.form.id;
      delete this.form.__typename;
    }
    this.customers = (await this.fetchCustomers()).map((item) => ({
      value: item.id,
      text: item.name,
    }));
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return false;
      if (this.id === 'new') this.insert();
      else this.update();
    },

    async insert() {
      this.loading = true;
      try {
        const user = cloneDeep(this.form)
        if (user.role === 'manager') delete user.customer_ids
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($user: SignUpInput!) {
              sign_up(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/usuarios');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        const user = cloneDeep(this.form)
        if (user.role === 'manager') user.customer_ids = null
        if (!user.password) delete user.password;
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $user: UpdateUserInput!) {
              update_user(id: $id, user: $user) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            user,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/usuarios');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query Users($id: uuid!) {
              user: users_by_pk(id: $id) {
                id
                name
                email
                role
                customers {
                  customer {
                    id
                  }
                }
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.user;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchCustomers() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query customers {
              customer: customers(order_by: [{ name: asc }]) {
                id
                name
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        return result.data.customer;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Usuário</h1>

    <v-card class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.name"
              label="Nome"
              name="Nome"
              :rules="validations.name"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="form.role"
              label="Perfil"
              outlined
              persistent-placeholder
              :items="roleItems"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              v-if="form.role !== 'manager'"
              v-model="form.customer_ids"
              :items="customers"
              :rules="validations.customer"
              label="Cliente"
              chips
              deletable-chips
              multiple
              small-chips
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.email"
              :rules="validations.email"
              label="Email"
              type="email"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.password"
              autocomplete="new-password"
              label="Senha"
              type="password"
              outlined
              persistent-placeholder
            />
          </v-col>

        </v-row>
        <div class="d-flex">
          <v-spacer />
          <v-btn color="primary" plain class="mr-2" to="/usuarios">Cancelar</v-btn>
          <v-btn color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
